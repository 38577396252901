import $ from 'jquery'
import 'bootstrap'
const Stickyfill = require('stickyfill')()


// CSS
import './node_modules/bootstrap/dist/css/bootstrap.min.css'
import './src/css/style.css'

$(function () {

    let scrollTopState = null
    function filter(event) {
        if ($(event.target).prop('checked')) {
            scrollTopState = $(window).scrollTop()
            $('#checklist section').hide()
            $('#checklist input[type=checkbox]:not(:checked)').closest('tr').hide()
            $('#checklist input[type=checkbox]:checked').parents('section').show()
        } else {
            $('#checklist section').show()
            $('#checklist input[type=checkbox]:not(:checked)').closest('tr').show()
            $(window).scrollTop(scrollTopState)
        }
    }

    $('#printButton').on('click', function () {
        if ($('#filterCheckbox').prop('checked')) {
            window.print()
        } else {
            $('#filterCheckbox').click()
            window.print()
            $('#filterCheckbox').click()
        }
    })
    $('#filterCheckbox').on('click', function (event) { filter(event) })

    Stickyfill.add($('#sidebar'))

})
